'use client'

import React, { useState } from 'react'
// import { signIn } from "aws-amplify/auth";
type User = {
  email: string
  password: string
}
const LoginPage: React.FC = () => {
  const [user, setUser] = useState<User>({
    email: '',
    password: '',
  })
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, [event.target.name]: event.target.value })
  }
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    // try {
    //   await signIn({
    //     username: event.currentTarget.email,
    //     password: event.currentTarget.password,
    //   });
    //   console.log("User logged in:", user);
    // } catch (e) {
    //   console.log(e);
    // }
  }
  return (
    <form onSubmit={handleSubmit}>
      <label>
        Email:
        <input
          type="email"
          name="email"
          value={user.email}
          onChange={handleInputChange}
          required
        />
      </label>
      <br />
      <label>
        Password:
        <input
          type="password"
          name="password"
          value={user.password}
          onChange={handleInputChange}
          required
        />
      </label>
      <br />
      <button type="submit">Login</button>
    </form>
  )
}
export default LoginPage
